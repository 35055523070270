import './App.css';
import HeaderComponent from './Header/Header';
import CompanySearch from './Dashboard/CompanySearch';
import CompanyDetail from './CompanyDetail/CompanyDetail';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import DocumentDetail from './DocumentDetail/DocumentDetail';
import LoginForm from './Authentication/Login';
import SignUpForm from './Authentication/Signup';
import CookieBanner from './UiRestrictions/CookieBanner';
import CompanyFilter from './CompanyFiltering/CompanyFilter';
import FooterComponent from './Header/Footer';
import PasswordReset from './Authentication/PasswordReset';
import PasswordResetEmailRequest from './Authentication/PasswordResetEmailRequest';
import ForeignCompaniesOwners from './MacroeconomicStats/ForeignCompaniesOwners';
import Contact from './Header/Contact';

import WebFont from 'webfontloader';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

WebFont.load({
  google: {
    families: ['Roboto:300,400,700', 'sans-serif']
  }
});

function App() {

  return (

    <Router>
      <div className="App">

        <HeaderComponent />
        <div className='mainContent'>
        <Switch>
          <Route path="/" exact={true} component={CompanySearch} />
          <Route path="/firmy/:id/:web_id?" component={CompanyDetail} />
          <Route path="/nahrat-dokument" component={DocumentUpload} />
          <Route path="/dokumenty/:id" component={DocumentDetail} />
          <Route path="/login" component={LoginForm} />
          <Route path="/registrace" component={SignUpForm} />
          <Route path="/filtrovani-firem" exact={true} component={CompanyFilter} />
          <Route path="/region/:id/filtrovani-firem" component={CompanyFilter} />
          <Route path="/reset-hesla-email" component={PasswordResetEmailRequest} />
          <Route path="/reset-hesla" component={PasswordReset} />
          <Route path='/statistika-zahranicni-firmy-cr' component={ForeignCompaniesOwners} />
          <Route path="/kontakt" component={Contact} />
          Firmy v ČR dle země původu konečných vlastníků
        </Switch>
        </div>

        <CookieBanner />
        <FooterComponent />
      </div>
    </Router>
  );
}


export default App;

import React from "react";
import { connect } from 'react-redux';
import { checkStatus } from '../helpers';
import { getDocument, getDocumentDone } from './state/documentDetailActions';
import './DocumentDetail.css';
import LimitationBanner from "../UiRestrictions/LimitationBanner";
import RawTablesList from "./RawTablesList";
import Constraint from "./Constraint";
import StatementTable from "./StatementTable";
import DocumentDetailTable from "./DocumentDetailsTable";
import XlsxDownloadButton from "./XlsxDownloadButton";
import DetectedDomainHeader from "./DetectedDomainHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { generateHeader } from '../helpers';
import RobotsBanner from "../UiRestrictions/RobotsBanner";
import { Helmet } from 'react-helmet';
import Skeleton from '@mui/material/Skeleton';
import { showLimitError } from "../Limiter/limiterActions";
import { showAdverts, hideAdverts } from "../Adverts/advertsActions";
import BigHorizontalBanner from '../Adverts/BigHorizontalBanner';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';

class DocumentDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uncollapsedTables: [],
            uncollapsedConstraints: [],
            showFullDomains: []
        }
    }

    componentDidMount() {
        this.props.startGettingDocument();
        this.props.getDocumentD(this.props.match.params.id, this.props.token, true);

    }

    changeCollapseOfTableDiv = (tableId, e) => {

        if (this.state.uncollapsedTables.includes(tableId)) {
            this.setState({ uncollapsedTables: this.state.uncollapsedTables.filter(table => table != tableId) });
        }
        else {
            this.setState({ uncollapsedTables: [...this.state.uncollapsedTables, tableId] });
        }

    }

    showFullDomain = (domainId) => {
        this.setState({ ...this.state, "showFullDomains": [...this.state.showFullDomains, domainId] })
    }

    hideFullDomain = (domainId) => {
        this.setState({ ...this.state, "showFullDomains": this.state.showFullDomains.filter(item => item !== domainId) })
    }

    changeCollapseOfConstraintDiv = (constraintId, e) => {

        if (this.state.uncollapsedConstraints.includes(constraintId)) {
            this.setState({ uncollapsedConstraints: this.state.uncollapsedConstraints.filter(constraint => constraint != constraintId) });
        }
        else {
            this.setState({ uncollapsedConstraints: [...this.state.uncollapsedConstraints, constraintId] });
        }

    }

    render() {

        const documentVerticalNavigation = <div className="verticalNavigation">
            <div className="navigationBackDiv" onClick={this.props.history.goBack}>
                <ArrowBackIcon fontSize="large" />
            </div>
        </div>

        const metadata = <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>

        if (this.props.showLimitError) {
            return <ErrorLimitationBanner />
        }

        if (this.props.isLoading) {
            return (
                <div className="documentDetail">
                    {metadata}
                    {documentVerticalNavigation}

                    <div className="documentDetailMain">

                        <div className='documentSkeleton'>
                            <Skeleton variant="rounded" height={800} />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.status != "processed") {
            return (
                <div className="documentDetail">

                    {metadata}
                    {documentVerticalNavigation}

                    <div className="documentDetailMain">

                        <LimitationBanner />
                        <RobotsBanner />
                        <div className="missingDocumentsDiv">
                            <p>Data z dokumentu teprve zpracováváme. Přijďte se na dokument podívat behěm následujících dnů / týdnů. </p>
                            <p className="termsDiv">Dokument je pravděpodobně naskenovaný anebo má velkou velikost. Data z takových souborů postupně připravujeme.</p>
                        </div>

                        <DocumentDetailTable
                            filename={this.props.filename}
                            createdAt={this.props.createdAt}
                            url={this.props.url}
                            token={this.props.token}
                            user={this.props.user}
                            revealAdverts={this.props.revealAdverts}
                        />

                        <div className="downloadButtons">

                            <XlsxDownloadButton
                                filename={this.props.filename}
                                data={[{ "domain_id": "vykaz_zisku_ztrat", "data": [] }, { "domain_id": "aktiva", "data": [] }, { "domain_id": "pasiva", "data": [] }]}
                                tables={[]}
                                revealAdverts={this.props.revealAdverts}
                            />

                        </div>

                        {!this.props.isLoading ?
                            <BigHorizontalBanner /> : null}

                    </div>
                </div>
            )
        }

        return (

            <div className="documentDetail">

                {metadata}
                {documentVerticalNavigation}

                <div className={`documentDetailMain ${this.props.showAdverts ? 'blurred' : ''}`}>

                    <LimitationBanner />
                    <RobotsBanner />

                    <DocumentDetailTable
                        filename={this.props.filename}
                        createdAt={this.props.createdAt}
                        url={this.props.url}
                        token={this.props.token}
                        user={this.props.user}
                        revealAdverts={this.props.revealAdverts}
                    />

                    <div className="downloadButtons">

                        <XlsxDownloadButton
                            filename={this.props.filename}
                            data={this.props.analysisData.matched_domains}
                            tables={this.props.analysisData.tables}
                            revealAdverts={this.props.revealAdverts}

                        />

                    </div>

                    <hr />

                    {this.props.analysisData.matched_domains.map(domain => (<div>

                        <DetectedDomainHeader
                            matchedDomainId={domain.domain_id}
                            detectedYear={domain.year}
                            showFullDomain={this.showFullDomain}
                            hideFullDomain={this.hideFullDomain}
                        />

                        {domain.data.filter(item => item.values.reduce(
                            (prev, current) => prev + (current.value != null && current.value != ""), 0)).length > 1 ?

                            domain.unsatisfied_constraints.length > 0 ?

                                <div>
                                    {domain.unsatisfied_constraints.map(unsatisfiedConstraint =>
                                        <Constraint
                                            constraintType={unsatisfiedConstraint.id}
                                            onClick={this.changeCollapseOfConstraintDiv}
                                            unsatisfiedConstraints={unsatisfiedConstraint.unsatisfied_constraints}
                                            uncollapsedList={this.state.uncollapsedConstraints}

                                        />
                                    )}
                                </div>

                                :

                                <div className="constraintsSuccess">
                                    <CheckCircleIcon style={{ color: "var(--success)" }} />
                                    <p className="constraintParagraph">Zdá se, že data jsou v pořádku. Všechny kontrolní součty sedí.</p>
                                </div>

                            :
                            <div className="noDataFoundDiv">
                                <WarningIcon style={{ color: "var(--warning)" }} />
                                <p className="constraintParagraph">Zdá se, že v dokumentu se nevyskytují požadovaná data. Doporučujeme si stáhnout originál dokumentu kliknutím na <b>Stáhnout originál</b> a data si ověřit.</p>
                            </div>
                        }

                        <StatementTable
                            domainId={domain.domain_id}
                            data={domain.data}
                            year={domain.year}
                            monetaryOrder={domain.monetary_order}
                            showFull={this.state.showFullDomains.includes(domain.domain_id)}
                        />

                        <BigHorizontalBanner />


                    </div>))}

                    <div>
                        <hr />
                        <h1>Detekované tabulky</h1>

                        {(this.props.analysisData.tables.length > 0) ?

                            <RawTablesList
                                onClick={this.changeCollapseOfTableDiv}
                                uncollapsedList={this.state.uncollapsedTables}
                                tables={this.props.analysisData.tables}
                            />

                            :

                            <p>Žádné tabulky nebyly nalezeny.</p>
                        }
                    </div>
                </div>
            </div>

        )

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail);


function mapStateToProps(state) {

    return {
        filename: state.documentDetail.filename,
        url: state.documentDetail.url,
        createdAt: state.documentDetail.createdAt,
        analysisData: state.documentDetail.analysisData,
        isLoading: state.documentDetail.isLoading,
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username'),
        status: state.documentDetail.status,
        showLimitError: state.limits.showLimitError,
        showAdverts: state.adverts.showAdverts
    }

}

function getDocumentData(document_id, token, isOneTime) {

    let url = null;
    if (isOneTime) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/documents/${document_id}?oneTime=true`
    } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/documents/${document_id}`
    }

    return fetch(url, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response =>
            Promise.all([response.status, response.json()])
        )

}

function mapDispatchToProps(dispatch) {

    return {
        startGettingDocument: () => {
            dispatch(getDocument());
        },
        getDocumentD: (documentId, token, isOneTime) => {
            getDocumentData(documentId, token, isOneTime)
                .then(([statusCode, document]) => dispatch(getDocumentDone(document, statusCode == 206 ? true : false)))
                .catch(error => dispatch(showLimitError()));
        },
        hideAdverts: () => {
            dispatch(hideAdverts());
        },
        revealAdverts: () => {
            dispatch(showAdverts())
        }
    }

}
import React from 'react';
import { checkStatus } from '../helpers';
import CompanyDetailBlock from './CompanyDetailBlock';
import FinancialDataBlock from './FinancialDataBlock';
import DocumentsBlock from './DocumentsBlock';
import { getCompany as getCompanyAction, companyFetched, getFinancialData as getFinancialDataAction, financialDataFetched, getDocuments as getDocumentsAction, documentsFetched, switchTab, getSimilarCompanies, similarCompaniesFetched, getBusinessCategories, businessCategoriesFetched } from './state/detailActions';
import { connect } from 'react-redux';
import "./CompanyDetail.css";
import LimitationBanner from '../UiRestrictions/LimitationBanner';
import { Helmet } from "react-helmet";
import RobotsBanner from '../UiRestrictions/RobotsBanner';
import DocumentsStats from './DocumentsStats';
import CustomDeletedIcon from '../Components/DeletedIcon';
import Skeleton from '@mui/material/Skeleton';
import MetricsBlock from './MetricsBlock';
import SimilarCompanies from './SimilarCompanies';
import { showLimitError } from '../Limiter/limiterActions';
import SimilarCompaniesBlock from './SimilarCompaniesBlock';
import BusinessCategoriesBlock from './BusinessCategoriesBlock';
import { Tabs, Tab } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import { cropString } from '../helpers';
import { CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BigHorizontalBanner from '../Adverts/BigHorizontalBanner';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';

class CompanyDetail extends React.Component {

    componentDidMount() {

        this.props.getCompany(this.props.match.params.id, this.props.token);

        if (this.props.user) {
            this.props.getFinancialData(this.props.match.params.id, this.props.token);
        }
        else {
            this.props.getFinancialData(this.props.match.params.id, this.props.token, true);
        }
        this.props.getDocuments(this.props.match.params.id, this.props.token);
        this.props.getSimilarCompanies(this.props.match.params.id, this.props.token)
        this.props.getBusinessCategories(this.props.match.params.id, this.props.token);
    }

    leaveCompanyDetail = () => {
        this.props.resetCompany()
        this.props.history.goBack()
    }

    render() {

        let metadata;
        let publicId;
        let title;
        let canonicalUrl;
        let slug;
        if (this.props.isCompanyLoading) {

            slug = window.location.href.split("/").pop()
            publicId = slug.includes('-') ? slug.split("-")[0] : "";
            canonicalUrl = window.location.href.replace("https://www.", "https://");
            title = slug.includes('-') ? slug.split("-").slice(1).map(name => name[0].toUpperCase() + name.slice(1)).join(" ") : "";

            title = title.replace(/ Sro$/, ' s.r.o.')
            title = title.replace(/ As$/, ' a.s.')

            const generatedContent = publicId ? `${title ? title + " ; " : ''}IČO:${publicId} ; URL: ${canonicalUrl}.` : `Detail firmy obsahující učetní uzávěrky firmy. URL: ${canonicalUrl}.`

            metadata = <Helmet>
                <title>{title}</title>
                <meta name="description" content={generatedContent} />
                <meta name="og:description" content={generatedContent} />
                <meta name="og:title" content={title} />
                <meta property="og:type" content="website" />
                <link href={canonicalUrl} rel="canonical"></link>
                {publicId ? null : <meta name="robots" content="noindex" />}
            </Helmet>
        } else {
            canonicalUrl = window.location.href.replace("https://www.", "https://");
            const generatedContent = `Název: ${this.props.company.name} ; IČO: ${this.props.company.public_id} ; Adresa: ${this.props.company.address} ; URL: ${canonicalUrl}.`
            title = `${this.props.company.name} - IČO:${this.props.company.public_id}, ${cropString(this.props.company.address, 30)}`
            slug = window.location.href.split("/").pop()
            publicId = slug.includes('-') ? slug.split("-")[0] : "";

            metadata = <Helmet>
                <title>{title}</title>
                <meta name="description" content={generatedContent} />
                <meta name="og:description" content={generatedContent} />
                <meta name="og:title" content={title} />
                <meta property="og:type" content="website" />
                <link href={canonicalUrl} rel="canonical"></link>
                {publicId ? null : <meta name="robots" content="noindex" />}
            </Helmet>
        }

        return (
            <div className='companyDetailMain'>

                <div className="verticalNavigation">
                    <div className="navigationBackDiv" onClick={this.leaveCompanyDetail}>
                        <ArrowBackIcon fontSize="large" />
                    </div>
                </div>
                {metadata}

                <div className="companyDetailRight">
                    <LimitationBanner />

                    {this.props.showLimitError ? <ErrorLimitationBanner /> : null}

                    {!this.props.isCompanyLoading ?
                        <div className='companyHeading'>
                            <div className='companyNameDiv'>
                                {this.props.company.deleted_at ?
                                    <CustomDeletedIcon locationType="detail" deletedAt={this.props.company.deleted_at} />
                                    : null}
                                <h1>{this.props.company.name}</h1>
                            </div>
                            <RobotsBanner />
                        </div> : <div className="loadingPlaceholder">
                            <h1>{title}</h1>
                            <CircularProgress />
                        </div>
                    }

                    <Tabs
                        defaultActiveKey="detail"
                        id="uncontrolled-tab-example"
                        className="mb-3 tabsMenu"
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >

                        <Tab eventKey="detail" title={<span><InfoIcon style={{ color: `var(--outline)` }} /> Detail</span>}>

                            {!this.props.isCompanyLoading ?
                                <CompanyDetailBlock
                                    name={this.props.company.name}
                                    id={this.props.company.public_id}
                                    address={this.props.company.address}
                                    url={canonicalUrl}
                                    slug={this.props.company.web_slug}
                                    createdAt={this.props.company.created_at}
                                    deletedAt={this.props.company.deleted_at}
                                    legalForm={this.props.company.legal_form}
                                    businessType={this.props.company.business_type}
                                    activityType={this.props.company.activity_type}
                                    fileId={this.props.company.file_id}
                                    user={this.props.user}
                                    orCompanyUrl={this.props.company.metadata.sources.or_company_detail}
                                    equityCapital={this.props.company.equity_capital}
                                />
                                : <div>
                                    <CompanyDetailBlock
                                        name={title}
                                        id={publicId}
                                        url={canonicalUrl}
                                        slug={slug}
                                    />
                                    <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>
                                </div>}

                            {!this.props.isCompanyLoading && !this.props.isFinancialDataLoading ?
                                <MetricsBlock yearData={this.props.financialData} switchTab={this.props.switchTab} user={this.props.user} />
                                : <div className='documentsSkeleton'>
                                    <Skeleton variant="rounded" height={300} />
                                </div>}

                            {(this.props.isCompanyLoading || this.props.isFinancialDataLoading || this.props.isDocumentsLoading) ?
                                 null : <BigHorizontalBanner />}

                            {!this.props.isDocumentsLoading ?
                                <DocumentsStats documents={this.props.documents} /> :
                                <div className='documentsSkeleton'>
                                    <Skeleton variant="rounded" height={300} />
                                </div>
                            }

                            {!this.props.isSimilarCompaniesLoading ?
                                <SimilarCompaniesBlock companies={this.props.companies.filter(c => c.public_id != publicId)} /> :
                                <div className='documentsSkeleton'>
                                    <Skeleton variant="rounded" height={300} />
                                </div>
                            }

                            {!this.props.isBusinessCategoriesLoading ?
                                <BusinessCategoriesBlock businessCategories={this.props.businessCategories} /> :
                                <div className='documentsSkeleton'>
                                    <Skeleton variant="rounded" height={300} />
                                </div>
                            }
                        </Tab>

                        {!this.props.isCompanyLoading ?
                            <Tab eventKey="financial_state" title={<span><PriceChangeIcon style={{ color: `var(--outline)` }} /> Finanční stav</span>}>

                                {!(this.props.isCompanyLoading || this.props.isFinancialDataLoading || this.props.isDocumentsLoading) ?
                                    <BigHorizontalBanner /> : null}

                                <FinancialDataBlock
                                    company={this.props.company.name}
                                    financialData={this.props.financialData}
                                    user={this.props.user}
                                    orDocumentsUrl={this.props.company.metadata.sources.or_documents_list}
                                />
                            </Tab> : null}

                        {!this.props.isDocumentsLoading && !this.props.isCompanyLoading ?
                            <Tab eventKey="documents" title={<span><DescriptionIcon style={{ color: `var(--outline)` }} /> Učetní závěrky</span>}>

                                <DocumentsBlock
                                    documents={this.props.documents}
                                    user={this.props.user}
                                    orDocumentsUrl={this.props.company.metadata.sources.or_documents_list}
                                />

                                {!(this.props.isCompanyLoading || this.props.isFinancialDataLoading || this.props.isDocumentsLoading) ?
                                    <BigHorizontalBanner /> : null}

                            </Tab> : null}

                        {!this.props.isCompanyLoading && !this.props.isSimilarCompaniesLoading ?
                            <Tab eventKey="similar_entities" title={<span><JoinLeftIcon style={{ color: `var(--outline)` }} /> Podobné subjekty</span>}>

                                {!(this.props.isCompanyLoading || this.props.isFinancialDataLoading || this.props.isDocumentsLoading) ?
                                    <BigHorizontalBanner /> : null}

                                <SimilarCompanies companyId={this.props.match.params.id} />
                            </Tab>
                            : null}
                    </Tabs>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);

function mapStateToProps(state) {

    return {
        financialData: state.detail.financialData,
        documents: state.detail.documents,
        companyId: state.detail.companyId,
        company: state.detail.company,
        companies: state.detail.companies,
        businessCategories: state.detail.businessCategories,
        isCompanyLoading: state.detail.isCompanyLoading,
        isDocumentsLoading: state.detail.isDocumentsLoading,
        isFinancialDataLoading: state.detail.isFinancialDataLoading,
        isSimilarCompaniesLoading: state.detail.isSimilarCompaniesLoading,
        isBusinessCategoriesLoading: state.detail.isBusinessCategoriesLoading,
        activeTabId: state.detail.activeTabId,
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username'),
        showLimitError: state.limits.showLimitError
    }

}

function mapDispatchToProps(dispatch) {

    return {
        resetCompany: () => {
            dispatch(getCompanyAction());
        },
        getCompany: (companyId, token) => {
            dispatch(getCompanyAction());
            getCompanyData(companyId, token).then(company => {
                dispatch(companyFetched(company));
                document.title = company.name;
            }
            )

        },
        getDocuments: (companyId, token) => {
            dispatch(getDocumentsAction());
            getDocuments(companyId, token).then(documents => dispatch(documentsFetched(documents)))

        },
        getFinancialData: (companyId, token, isOneTime) => {
            dispatch(getFinancialDataAction());
            getFinancialData(companyId, token, isOneTime)
                .then(financialData => dispatch(financialDataFetched(financialData)))
                .catch(error => dispatch(showLimitError()))

        },
        switchTab: (tabId) => {
            dispatch(switchTab(tabId));
        },
        getSimilarCompanies: (companyId, token) => {
            dispatch(getSimilarCompanies());
            getSimilarCompaniesOverApi(companyId, token).then((response) => dispatch(similarCompaniesFetched(response.results)))
        },
        getBusinessCategories: (companyId, token) => {
            dispatch(getBusinessCategories());
            getBusinessCategoriesOverApi(companyId, token).then((response) => dispatch(businessCategoriesFetched(response.results)))
        }

    }

}

function getCompanyData(companyId, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
}

function getDocuments(companyId, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/documents`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
        .then(data => data.results)
}

function getFinancialData(companyId, token, isOneTime) {

    let url = ''
    if (isOneTime) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/financial_data?oneTime=true`
    } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/financial_data`
    }

    return fetch(url, {
        method: 'get',
        headers: generateHeader(token),

    }).then(checkStatus)
        .then((response) => response.json())
};

function getSimilarCompaniesOverApi(companyId, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/similar_companies`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}

function generateHeader(token) {

    return token ? { 'Authorization': `Bearer ${token}` } : {};

}

function getBusinessCategoriesOverApi(companyId, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/business_categories`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}
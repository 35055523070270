import React from 'react';
import './CookieBanner.css';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module';

class CookieBanner extends React.Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            showCookie: cookies.get('finvy_consent') ? false : true
        }
    }

    initGtm = () => {

        const tagManagerArgs = {
            gtmId: 'GTM-54G9PLS'
        }

        if (window.location.href.indexOf("financni-vykazy.cz") != -1) {
            TagManager.initialize(tagManagerArgs)
        }

    }

    acceptCookies = () => {
        const cookies = new Cookies();
        cookies.set('finvy_consent', true, { path: '/', maxAge: 2592000  });
        this.setState({ showCookie: false })
    }

    rejectCookies = () => {
        const cookies = new Cookies();
        cookies.set('finvy_consent', false, { path: '/', maxAge: 2592000  });
        this.setState({ showCookie: false })
    }

    trackingIsEnabled = () => {

        const cookies = new Cookies();
        const trackingBool = cookies.get('finvy_consent');


        if (trackingBool === 'true') {
            return true
        }
        else {
            return false
        }
    }

    initTracking = () => {

        if (this.trackingIsEnabled()) {
            this.initGtm();
        }
    }

    render() {
        return (
            <div>

                {this.state.showCookie ?
                    <div className='cookiesBanner'>
                        {this.initGtm()}
                        <h3>Používáme cookies</h3>
                        <p>Přijmutím nám umožníte vylepšování webové aplikace díky cookies a dalším technologiím, které umožňují analýzu provozu.</p>
                        <div className='cookiesButtons'>
                            <button className='cookiesAccept' onClick={this.acceptCookies}>Přijmout</button>
                            <button className='cookiesReject' onClick={this.rejectCookies}>Odmítnout</button>
                        </div>
                    </div>


                    : this.initTracking()

                }
            </div>
        )
    }

}

export default CookieBanner;